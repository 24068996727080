import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImageItem from '../image-item/large';
import Popover from './popover';

const defaultUser = { full_name: '"Verwijderde gebruiker"' };

class ProfileLarge extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    children: PropTypes.any.isRequired,
    meta: PropTypes.any,
    inverted: PropTypes.bool,
    className: PropTypes.string,
    size: PropTypes.number,
  };

  static defaultProps = {
    enablePopover: false,
  };

  render() {
    const {
      children, meta, inverted, className, size, enablePopover,
    } = this.props;

    // User could be null, in that case setting a default value through defaultProps won't
    // work because they only check for undefined values
    const user = this.props.user || defaultUser;

    return (
      <ImageItem
        mask
        image={user.profile_img}
        name={inverted ? <small>{user.full_name}</small> : user.full_name}
        meta={meta}
        inverted={inverted}
        className={['Profile Profile--large', className].join(' ')}
        size={size}
        ImageWrapper={enablePopover ? Popover : null}
        wrapperProps={{ user }}
      >
        {children}
      </ImageItem>
    );
  }
}

export default ProfileLarge;
