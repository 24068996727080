import moment from 'moment';
import Api from '../../../common/services/api';
import * as exchangeUtil from '../utils/exchange';
import { EExchangeTypes } from '../definitions';

export const FLEXCHANGE_ADD_EXCHANGE = 'flexchange/POST_EXCHANGE';

export default (values) => async (dispatch, getState) => {
  const { network: { selected } } = getState();

  const payload = {
    type: values.type === 'network' ? 'ALL' : values.type.toUpperCase(),
    date: moment(values.date).format('YYYY-MM-DD'),
    description: values.description,
    start_time: exchangeUtil.convertTimeToMoment(
      values.start_time,
      moment(values.date)
    ).toISOString(),
    end_time: values.end_time ?
      exchangeUtil.convertTimeToMoment(values.end_time, moment(values.date)) :
      null,
    shift_id: values.shift_id,
  };

  if (values.type === EExchangeTypes.FUNCTION) payload.values = values.function_ids || [];
  if (values.type === EExchangeTypes.USER) payload.values = values.user_ids || [];

  const { data } = await Api.post(`/v2/networks/${selected}/exchanges`, payload);

  return dispatch({
    type: FLEXCHANGE_ADD_EXCHANGE,
    exchangeId: data.id,
    exchange: exchangeUtil.transfromExchange(data),
  });
};
