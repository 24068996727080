import * as React from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const ShiftItem = ({ shift, t }) => (
  <div className="Shift">
    <div className="Shift__Date">{moment(shift.date).format('dddd DD MMMM YYYY')}</div>

    <div className="Shift__Times">
      {moment(shift.start_time).format('HH:mm')}
      <span className="Shift__Times__Spacer">-</span>
      {shift.end_time ? moment(shift.end_time).format('HH:mm') : t('schedules:shift_no_end_time')}
    </div>

    <div>
      <small>
        {!shift.break || shift.break === '00:00' || shift.break === '00:00:00'
          ? t('schedules:shift_no_break')
          : t('schedules:shift_break', { break: shift.break.substr(0, 5) })}
      </small>
    </div>

    {shift.function && <div className="Shift__Function">{shift.function.name}</div>}
  </div>
);

export default withTranslation()(ShiftItem);
