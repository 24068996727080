import Api from '../../../common/services/api';

export const FLEXCHANGE_RECEIVE_RECEIVERS = 'flexchange/RECEIVE_RECEIVERS';

export default (shiftId) => async (dispatch, getState) => {
  const { network: { selected } } = getState();

  const { data: items } = await Api.get(`/v2/networks/${selected}/shifts/${shiftId}/available`);

  return dispatch({
    type: FLEXCHANGE_RECEIVE_RECEIVERS,
    shiftId,
    items,
  });
};
