import React from 'react';

const CarouselCard = ({ children, position }) => {
  if (position !== undefined && position !== 0) return null;

  return (
    <div className="Carousel__Card">
      {children}
    </div>
  );
};

export default CarouselCard;
