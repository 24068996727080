import PropTypes from 'prop-types';
import React from 'react';

const ToolBar = ({ children, className }) => {
  const classNames = ['ToolBar'];
  if (className) classNames.push(className);

  return <div className={classNames.join(' ')}>{children}</div>;
};

ToolBar.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default ToolBar;
