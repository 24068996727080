import React from 'react';
import { omit } from 'lodash';
import { BaseFieldProps, Field, WrappedFieldInputProps, WrappedFieldProps } from 'redux-form';

import { Checkbox } from '../checkbox';
import type { CheckboxOwnProps } from '../checkbox';

type ReduxFormFieldProps = CheckboxOwnProps & {
  id: string;
  input: Omit<WrappedFieldInputProps, 'onChange' | 'value'> & {
    value: string[];
    onChange: (newValue: string[]) => void;
  }
};

/**
 * Sets the value based on if id is included in the provided value Array.
 * Will remove/add the id base on the newValue and will pass the Array to onChange.
 */
const ReduxFormField = ({ id, input, meta, ...props }: Omit<WrappedFieldProps, 'input'> & ReduxFormFieldProps) => {
  return (
    <Checkbox
      error={meta.touched && meta.error}
      {...input}
      value={!!input.value.includes(id)}
      onChange={(newValue) => {
        input.onChange(newValue ?
          [...input.value, id] :
          input.value.filter((_id: string) => _id !== id)
        );
      }}
      {...omit(props, ['meta', 'input'])}
    />
  );
};

/**
 * @deprecated This component is only
 * here to support state management of legacy code, that should actually
 * be handled by the component implementing the form
 */
const ReduxFormCheckboxGroup = ({ name, ...props }: BaseFieldProps & ReduxFormFieldProps) => (
  <Field
    name={name}
    component={ReduxFormField}
    props={props}
  />
);

export default ReduxFormCheckboxGroup;
