import * as React from 'react';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { Button } from '../button';
import Spinner from '../spinner';
import Icon from '../icon';

const createDateString = (date) => {
  const start = moment(date).startOf('isoweek');
  const end = moment(date).endOf('isoweek');

  let string = start.format('D');

  if (start.month() !== end.month()) string += ` ${start.format('MMM')}`;

  string += ` - ${end.format('D MMM')}`;

  if (start.year() !== end.year()) string += ` ${end.year()}`;

  return string;
};

const WeekSelector = ({
  date,
  min,
  max,
  isFetching,
  ...props
}) => {
  const index = date.diff(moment().startOf('isoweek'), 'weeks');

  return (
    <div className="WeekSelector">
      <Button
        size="large"
        disabled={date.isoWeek() === moment().isoWeek()}
        onClick={props.onGoToToday}
        className="hidden-sd"
      >
        <Trans i18nKey="common:week_selector_today" />
      </Button>

      <div className="Button Button--size-large Button--default WeekSelector__Navigation">
        {
          min === undefined || index > min ?
            <Icon type="chevron_left" onClick={props.onGoToPreviousWeek} /> :
            <div className="WeekSelector__Switch" />
        }

        <Trans
          i18nKey="common:week_selector_week_number"
          values={{ weekNumber: date.isoWeek() }}
          components={[<span className="hidden-md" style={{ marginRight: '.3em' }} />]}
        />

        {
          !max || index < max ?
            <Icon type="chevron_right" onClick={props.onGoToNextWeek} /> :
            <div className="WeekSelector__Switch" />
        }
      </div>

      <div className="WeekSelector__Date">{createDateString(date)}</div>

      {isFetching && <Spinner size="large" />}
    </div>
  );
};

export default WeekSelector;
