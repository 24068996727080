import PropTypes from 'prop-types';
import React from 'react';
import BootstrapModal from 'react-bootstrap/Modal';
import { withTranslation } from 'react-i18next';
import { Button } from '../button';

const CarouselFooter = ({
  index, maxIndex, goPrevious, goNext, onCancel, onSubmit, submitText, submitting, invalid, t,
}) => (
  <BootstrapModal.Footer>
    <div className="pull-right">
      <Button onClick={() => (index === 0 ? onCancel() : goPrevious())}>
        {index === 0 ? t('common:carousel_footer_cancel') : t('common:carousel_footer_previous')}
      </Button>
      <Button
        type="primary"
        disabled={invalid}
        isLoading={submitting}
        onClick={() => (index === maxIndex ? onSubmit() : goNext())}
      >
        {index === maxIndex
          ? submitText || t('common:carousel_footer_save')
          : t('common:carousel_footer_next')}
      </Button>
    </div>
  </BootstrapModal.Footer>
);

CarouselFooter.propTypes = {
  index: PropTypes.number.isRequired,
  maxIndex: PropTypes.number.isRequired,
  goPrevious: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
};

export default withTranslation()(CarouselFooter);
