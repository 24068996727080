import * as React from 'react';
import moment from 'moment';

const ShiftItem = ({ item, onClick }) => (
  <div className="ShiftItem" onClick={() => onClick(item)}>
    {moment(item.start_time).format('dddd D MMMM, HH:mm')}
    {' '}
    -
    {moment(item.end_time).format('HH:mm')}
  </div>
);

export default ShiftItem;
