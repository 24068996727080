import * as R from 'ramda';
import Api from '../../../common/services/api';

export const RECEIVE_SHIFTS = 'schedules/RECEIVE_SHIFTS';

export const call = () => (dispatch, getState) => {
  const { network: { selected } } = getState();

  return Api.get(`/v2/networks/${selected}/users/me/shifts`);
};

export default () => async (dispatch, getState) => {
  const { network: { selected } } = getState();

  const { data } = await Api
    .get(`/v2/networks/${selected}/users/me/shifts`);

  dispatch({
    type: RECEIVE_SHIFTS,
    items: data.map((shift) => R.assoc('id', shift.id || shift.start_time, shift)),
  });
};
